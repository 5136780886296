<template>
    <div style="display:flex;flex-direction:column;height: 100%;">
        <div v-if="active == 0">
            <el-alert :title="connectStatus" v-if="connectStatus" center show-icon type="error" :closable="false">
            </el-alert>
            <el-card style=" background-color:#E6A23C;" :body-style="{ display: 'flex', justifyContent: 'space-around' }">
                <div
                    style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
                    <i class="el-icon-s-opportunity"
                        :style="'font-size:30px;' + 'color:' + (deviceData32.jiaRe ? '#67C23A' : 'white')"></i>
                    <span :style="'color:' + (deviceData32.jiaRe ? '#67C23A' : 'white')">加热</span>
                </div>
                <div
                    style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
                    <i class="el-icon-s-opportunity"
                        :style="'font-size:30px;' + 'color:' + (deviceData32.qiDongTingZhi ? '#67C23A' : 'white')"></i>
                    <span :style="'color:' + (deviceData32.qiDongTingZhi ? '#67C23A' : 'white')">小车</span>
                </div>
                <div
                    style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;border-right:solid 1px #DCDFE6">
                    <i class="el-icon-s-opportunity"
                        :style="'font-size:30px;' + 'color:' + (deviceData32.youBeng ? '#67C23A' : 'white')"></i>
                    <span :style="'color:' + (deviceData32.youBeng ? '#67C23A' : 'white')">油泵</span>
                </div>
                <div style="flex:1;display:flex;flex-direction:column;color:white;font-weight:900;text-align:center;">
                    <i class="el-icon-s-opportunity"
                        :style="'font-size:30px;' + 'color:' + (deviceData32.qiDongTingZhi ? '#67C23A' : 'white')"></i>
                    <span :style="'color:' + (deviceData32.qiDongTingZhi ? '#67C23A' : 'white')">启动</span>
                </div>
            </el-card>
            <el-card style="background-color: #409EFF;" :body-style="{ padding: '0px' }">
                <van-divider style="color:white;font-weight: bolder;margin: 5px auto;font-size: 20px;">温度</van-divider>
                <div
                    style="color:white;display: flex;align-items:center;justify-content: center;font-size: 50px;font-weight: bolder;">
                    {{ deviceData32.wenDu }}℃</div>
            </el-card>
            <el-card style="background-color: #67C23A;" :body-style="{ padding: '0px' }">
                <van-divider style="color:white;font-weight: bolder;margin: 5px auto;font-size: 20px;">次数</van-divider>
                <div
                    style="color:white;display: flex;align-items:center;justify-content: center;font-size: 50px;font-weight: bolder;">
                    {{ deviceData32.jiShu }}次</div>
            </el-card>

            <div
                style="display:flex;flex:1;justify-content: space-around;align-items: center; border-radius: 10px;border: solid 0px #409EFF;">
                <el-button type="primary" circle class="myButton"
                    @click="handleSendCMD('35', userConfig.miYao)">上升</el-button>

                <el-button type="primary" circle class="myButton"
                    @click="handleSendCMD('36', userConfig.miYao)">下降</el-button>
            </div>
            <van-divider></van-divider>
            <div style="display:flex;flex:1;justify-content: space-around;align-items: center; ">
                <el-button type="primary" circle class="myButton"
                    @click="handleSendCMD('37', userConfig.miYao)">油泵</el-button>
                <el-button type="danger" circle class="myButton" @click="handleSendCMD('38')">停止</el-button>
            </div>
            <van-divider></van-divider>
            <div style="display:flex;flex:1;justify-content: space-around;align-items: center; ">
                <el-button type="success" circle class="myButton"
                    @click="handleSendCMD('33', userConfig.miYao)">启动</el-button>
                <el-button type="danger" circle class="myButton" @click="handleSendCMD('34')">停止</el-button>
            </div>
        </div>
        <div v-else>
            <van-cell-group title="密钥设置">
                <van-field v-model.number="userConfig.miYao" label="密钥" placeholder="请输入密钥" type="digit" maxlength="5"
                    input-align="right"></van-field>
            </van-cell-group>
            <van-cell-group title="标签打印机序列号">
                <van-cell center title="试验结束后打印标签">
                    <template #right-icon>
                        <van-switch v-model="deviceConfig.ifPrintLabel" size="15"></van-switch>
                    </template>
                </van-cell>
                <van-field v-model="deviceConfig.labelPrinterSN" v-show="deviceConfig.ifPrintLabel" label="打印机序列号" clearable
                    placeholder="请输入标签打印机序列号" @click-right-icon="scanXpPrint" right-icon="scan"></van-field>
            </van-cell-group>
            <van-button style="margin-top:20px;" round type="info" block @click="saveConfig">保存设置</van-button>

            <van-cell-group title="参数设置">
                <van-field label="计数值" placeholder="请输入计数值" maxlength="4" clearable v-model="jiShuZhi" type="digit"
                    input-align="right"></van-field>
                <van-field label="温度值" placeholder="请输入温度值" maxlength="3" clearable v-model="wenDuZhi"
                    input-align="right"></van-field>
            </van-cell-group>
            <van-button style="margin-top:20px;" round type="info" block @click="send3A">保存参数到设备</van-button>

        </div>
        <div style="height:50px"></div>
        <van-tabbar v-model="active" @change="handleChange">
            <van-tabbar-item icon="desktop-o">操作</van-tabbar-item>
            <van-tabbar-item icon="setting-o">设置</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);
export default {
    data() {
        return {
            active: 0,
            deviceData32: {},
            connectStatus: "",
            mtf: {}, //websock返回给浏览器的数据
            userConfig: {},
            deviceConfig: {},
            jiShuZhi: null,
            wenDuZhi: null,
        };
    },
    props: ["serialNumber"],
    methods: {
        handleChange() {
            let that = this;
            if (that.active == 1) {
                that.handleSendCMD("39");
            }
        },
        handleSendCMD(cmd, data) {
            let that = this;
            that.websock.send(
                JSON.stringify({
                    serialNumber: that.serialNumber,
                    command: cmd,
                    data: data,
                })
            );
        },
        send3A() {
            let that = this;
            if (!(that.jiShuZhi == "" || that.wenDuZhi == "")) {
                that.handleSendCMD("3A", that.userConfig.miYao + "," + that.jiShuZhi + "," + that.wenDuZhi);
            } else {
                that.$notify({ type: "danger", message: "保存失败" });
            }
        },

        saveUserConfig() {
            let that = this;
            that.axios.post("CZCX01_User_Config/SaveModel", that.userConfig).then((response) => {
                if (response.data.code == 101) {
                    that.userConfig = response.data.data;
                    that.$notify({ type: "success", message: "保存成功" });
                }
            });
        },
        saveDeviceConfig() {
            let that = this;
            that.axios.post("CZCX01_Device_Config/SaveModel", that.deviceConfig).then((response) => {
                if (response.data.code == 101) {
                    that.deviceConfig = response.data.data;
                    that.$notify({ type: "success", message: "保存成功" });
                }
            });
        },
        saveConfig() {
            let that = this;
            that.saveUserConfig();
            that.saveDeviceConfig();
        },
        scanXpPrint() {
            let that = this;
            that.wx.scanQRCode({
                needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                scanType: ["qrCode"],
                success: function (res) {
                    that.deviceConfig.labelPrinterSN = res.resultStr;
                    that.saveXpPrint();
                },
            });
        },
    },
    mounted() {
        let that = this;
        //初始化webSocket
        const wsuri = process.env.VUE_APP_WebSocketURL1;
        that.websock = new WebSocket(wsuri);

        that.websock.onmessage = function (e) {
            //console.log(e);
            //数据接收
            that.connectStatus = "";
            that.mtf = JSON.parse(e.data);

            if (that.mtf.code == 101) {
                if (that.mtf.cmd == "32") {
                    that.deviceData32 = that.mtf.data;
                } else if (that.mtf.cmd == "33") {
                    if (that.mtf.data.miYaoZhuangTai33 == 0) {
                        that.$notify({ type: "danger", message: "密钥错误" });
                    }
                } else if (that.mtf.cmd == "35") {
                    if (that.mtf.data.miYaoZhuangTai35 == 0) {
                        that.$notify({ type: "danger", message: "密钥错误" });
                    }
                } else if (that.mtf.cmd == "36") {
                    if (that.mtf.data.miYaoZhuangTai36 == 0) {
                        that.$notify({ type: "danger", message: "密钥错误" });
                    }
                } else if (that.mtf.cmd == "37") {
                    if (that.mtf.data.miYaoZhuangTai37 == 0) {
                        that.$notify({ type: "danger", message: "密钥错误" });
                    }
                } else if (that.mtf.cmd == "39") {
                    that.jiShuZhi = that.mtf.data.jiShuZhi;
                    that.wenDuZhi = that.mtf.data.wenDuZhi;
                } else if (that.mtf.cmd == "3A") {
                    if (that.mtf.data.zhuangTai == 1) {
                        that.$notify({ type: "success", message: "保存到设备成功" });
                    }
                }
            } else {
                that.connectStatus = "未连接,请配置设备WIFI并连上网络";
            }

            setTimeout(() => {
                that.websock.send(
                    JSON.stringify({
                        serialNumber: that.serialNumber,
                        command: "32",
                    })
                );
            }, 200);
        };
        that.websock.onopen = function () {
            that.websock.send(
                JSON.stringify({
                    serialNumber: that.serialNumber,
                    command: "32",
                })
            );
        };
        that.websock.onerror = function () {
            that.connectStatus = "请刷新页面";
        };
        that.websock.onclose = function () {
            that.connectStatus = "请刷新页面";
        };
        //获取用户设备配置
        that.axios.post("CZCX01_User_Config/GetModel", { onlyText: that.serialNumber }).then((response) => {
            that.userConfig = response.data.data;
        });
        //获取设备配置
        that.axios.post("CZCX01_Device_Config/GetModel", { onlyText: that.serialNumber }).then((response) => {
            that.deviceConfig = response.data.data;
        });
        //获取公众号扫码的凭证
        that.axios.post("OfficialAccount/GetWXScanAPI", { onlyText: window.location.href }).then(function (response) {
            that.wx = require("weixin-js-sdk");
            that.wx.config({
                debug: false,
                appId: response.data.data.appId,
                timestamp: response.data.data.timestamp,
                nonceStr: response.data.data.nonceStr,
                signature: response.data.data.signature,
                jsApiList: ["scanQRCode"],
            });
            that.wx.ready(function () { });
            that.wx.error(function (err) {
                alert("error" + JSON.stringify(err));
            });
        });
    },
};
</script>

<style scoped>
.myButton {
    width: 80px;
    height: 80px;
    font-size: 25px;
    font-weight: bolder;
}
</style>